import { configureStore } from "@reduxjs/toolkit";
import productsReducer from './products/products.reducer'
import authReducer from "./auth/auth.reducer";
import logger from 'redux-logger'

const store = configureStore({
    reducer: {
        product: productsReducer,
        auth: authReducer
    },
    middleware: process.env.NODE_ENV === 'development'? [logger] : []
})

export type RootState = ReturnType<typeof store.getState>

export default store