import { createSlice } from "@reduxjs/toolkit";
import { Product } from "../../components/stock-container/products/product";
import { SetDisplayProductModalAction, SetSearchValueAction, SetSelectedProductAction } from "./products.action";


export interface IProductState {
  searchValue: string
  selectedProduct: Product | null
  productModalIsOpen: boolean
  deleteProductModalIsOpen: boolean
}

const INITIAL_STATE: IProductState = {
  searchValue: '',
  selectedProduct: null,
  productModalIsOpen: false,
  deleteProductModalIsOpen: false
}

export const productsSlice = createSlice({
  name: "products",
  initialState: INITIAL_STATE,
  reducers: {
    setSearchValue: (state, action: SetSearchValueAction) => {
      state.searchValue = action.payload
    },
    setSelectedProduct: (state, action: SetSelectedProductAction) => {
      state.selectedProduct = action.payload
    },
    setDisplayProductModal: (state, action: SetDisplayProductModalAction) => {
      state.productModalIsOpen = action.payload
    },
    setDisplayDeleteProductModal: (state, action: SetDisplayProductModalAction) => {
      state.deleteProductModalIsOpen = action.payload
    }
  }
});


export const { 
  setSearchValue, 
  setSelectedProduct,
  setDisplayProductModal,
  setDisplayDeleteProductModal
} = productsSlice.actions
export default productsSlice.reducer