import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom'
import Master from './pages/master/Master';
import Stock from './pages/stock/Stock';
import sadFaceImg from './assets/homepage/images/sad-face.jpg'
import useAuth from './helper/useAuth';
import './App.css';

function App() {

  useAuth()

  return (
    <div className="App">
      {/* <Routes>
        <Route path='/' element={<Master />} />
        <Route path='/stock' element={<Stock />} />
        <Route path='' element={<Navigate to='/' />} />
        <Route path='*' element={<Navigate to='/' />} />
      </Routes> */}
      <main style={{ textAlign: 'center' }}>
        This application is still in development, please check back later

        <div>
          <img alt='sad-face' src={sadFaceImg} />
        </div>
      </main>
    </div>
  );
}

export default App;
