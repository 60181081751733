import { createSlice } from "@reduxjs/toolkit";

interface AuthAction {
    type: string
    payload: boolean

}

export interface IAuthState {
    isAuth: boolean
}

const INITIAL_STATE: IAuthState = {
    isAuth: false
}

export const authSlice = createSlice({
    name: 'auth',
    initialState: INITIAL_STATE,
    reducers: {
        setAuth: (state, action: AuthAction) => {
            state.isAuth = action.payload
        }
    }
})

export const { setAuth } = authSlice.actions
export default authSlice.reducer