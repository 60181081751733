import axios from "axios"

let token: string

export const setToken = (t: string) => {
    token = t
}

const AuthService = () => {
    const authApi = axios.create({
        baseURL: 'https://localhost:44350/api/Auth/',
        headers: { Authorization: `Bearer ${token}`}
    })
    
    return { authApi }
}

export default AuthService