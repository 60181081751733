import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useCookies } from "react-cookie"
import { setAuth } from "../redux/auth/auth.reducer"
import { setToken } from '../common/services/auth.service';
import axios from "axios"

const useAuth = async (): Promise<void> => {
    const dispatch = useDispatch()
    const [cookies, setCookie] = useCookies()

    useEffect(() => {
        if(window.location.href.includes('netlify')){
            window.open('https://jjofosa.com.ng/', '_self')
            return;
        }
        const { token } = cookies
        if(token){
            setToken(token)
            dispatch(setAuth(true))
        } else {
            getToken()
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const getToken = async () => {
        if(process.env.NODE_ENV === 'development'){
            const tokenKey = process.env.REACT_APP_TOKEN_KEY
            const token = await (await axios.get(`https://localhost:44350/api/Auth/GetToken/${tokenKey}`)).data
    
            if(!!token){
                const date: Date = new Date();
                const futureDate: Date = new Date(date.setHours(date.getHours() + 10))
                setCookie("token", token, { expires: futureDate })
                setToken(token)
                dispatch(setAuth(true))
            } else {
                dispatch(setAuth(false))
            }
        } else {
            dispatch(setAuth(false))
        }
    }
}

export default useAuth